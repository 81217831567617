import React, { useState } from "react";
import "./menu.css";
import { Divide as Hamburger } from "hamburger-react";

const Menu = () => {
  const [color, setColor] = useState(false);

  const handleColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", handleColor);

  const [isOpen, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!isOpen);
  };
  const closeMobileMenu = () => {
    setOpen(false);
  };

  return (
    <nav
      className={
        isOpen
          ? "navbar expanded"
          : "navbar" && color
          ? "header nav-bg"
          : "header"
      }
      onScroll={handleColor}
    >
      <h1 className="navbar-logo">
        Mahdi <span>Logo</span>
      </h1>
      <div className={isOpen ? "menu" : ""}>
        <ul className="links">
          <li>
            <a href="#home" className="" onClick={closeMobileMenu}>
              Home
            </a>
          </li>
          <li>
            <a href="#about" onClick={closeMobileMenu}>
              About Me
            </a>
          </li>
          <li>
            <a href="#skills" onClick={closeMobileMenu}>
              Extracurricular
            </a>
          </li>
          <li>
            <a href="#contact" onClick={closeMobileMenu}>
              Contact
            </a>
          </li>
        </ul>

        <div className="menu-icon" onClick={handleToggle}>
          <Hamburger toggled={isOpen} toggle={() => setOpen(!isOpen)} />
        </div>
      </div>
    </nav>
  );
};

export default Menu;

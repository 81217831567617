import React from "react";
import Header2 from "../../components/header/Header2";
import "./form2.css";
import {
  ColdFeed,
  Stockpiles,
  Dryer,
  DustCollector,
  AggregateScreening,
  HotAggregateBins,
  AggregateScreening2,
  ThermometricEquipment,
  WeighingAndMeasuringApparatus,
} from "./form2Data";

const TableData = (item) => {
  return (
    <>
      <tr key={item.id}>
        <td className="col-num">{item.num}</td>
        <td>{item.text}</td>
        <td className="col-ref">
          {item.ref} <br /> {item.appendix}
        </td>
        <td>
          <select name="result" id="result">
            <option selected={true} disabled="disabled">
              select:
            </option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
        </td>
        <td>Note</td>
      </tr>
    </>
  );
};

const Form2 = () => {
  return (
    <>
      <div className="container">
        <Header2 />
        <div className="form-t2">
          <table>
            <tr>
              <td>
                Lab No.:
                <input
                  type="text"
                  className="table-2"
                  placeholder="Enter the name of the industury..."
                />
              </td>
              <td>
                Recieved Date:
                <input
                  type="text"
                  className="table-2"
                  placeholder="Enter the recieved date..."
                />
              </td>
            </tr>
            <tr>
              <td>
                Client Name:
                <input
                  type="text"
                  className="table-2"
                  placeholder="Enter the client name..."
                />
              </td>
              <td>
                Test Date Started:
                <input
                  type="date"
                  className="table-2"
                  placeholder="Enter the test started date..."
                />
              </td>
            </tr>
            <tr>
              <td>
                Plant Capacity & Serial No.:
                <input
                  type="text"
                  className="table-2"
                  placeholder="Enter the plant cpaacity & serial number..."
                />
              </td>
              <td>
                Test Date Completed:
                <input
                  type="date"
                  className="table-2"
                  placeholder="Enter the test completed date..."
                />
              </td>
            </tr>
            <tr>
              <td>
                Plant Location:
                <input
                  type="text"
                  className="table-2"
                  placeholder="Enter the plant location..."
                />
              </td>
              <td>
                Evaluation Team:
                <input
                  type="text"
                  className="table-2"
                  placeholder="Enter the evaluation team..."
                />
              </td>
            </tr>
          </table>
        </div>
        {/* Form Table */}
        <table className="form2-table form2-format">
          <tr className="form2-header">
            <td>No.</td>
            <td>Evaluation Parameters</td>
            <td>QCS 2014 References</td>
            <td className="sub-td">
              <tr>Results</tr>
              {/* <td>Yes</td>
              <td>No</td> */}
            </td>
            <td>Notes</td>
          </tr>
          <th colSpan="5">Aggregate Stockpiles</th>
          {Stockpiles.map(TableData)}
          <th colSpan="5">Aggregate Cold Feed</th>
          {ColdFeed.map(TableData)}
          <th colSpan="5">Dryer</th>
          {Dryer.map(TableData)}
          <th colSpan="5">Dust Collector</th>
          {DustCollector.map(TableData)}
          <th colSpan="5">Aggregate Screening</th>
          {AggregateScreening.map(TableData)}
          <th colSpan="5">Hot Aggregate Bins</th>
          {HotAggregateBins.map(TableData)}
          <th colSpan="5">Aggregate Screening</th>
          {AggregateScreening2.map(TableData)}
          <th colSpan="5">Thermometric Equipment</th>
          {ThermometricEquipment.map(TableData)}
          <th colSpan="5">Weighing and Measuring Apparatus</th>
          {WeighingAndMeasuringApparatus.map(TableData)}
        </table>
      </div>
    </>
  );
};

export default Form2;

import React from "react";
import "./home.css";
// import * as BsIcon from "react-icons/bs";
import * as FaIcons from "react-icons/fa";

const Home = () => {
  return (
    <>
      <section className="home" id="home">
        <div className="home-text">
          <div className="slide">
            <span className="one">Hello</span>
            <span className="two">I'm</span>
          </div>
          <h1>Mahdi Al Qallaf</h1>
          <h3>
            Handball <span>Player</span>
          </h3>
          <p>A professional handball player in the national team of Kuwait.</p>
          <div className="button">
            {/* <a href="#1" className="btn">
              Say Hello
            </a> */}
            <a
              href="https://www.youtube.com/@mahdialqallaf10"
              target="_blank"
              rel="noreferrer"
              className="btn2"
            >
              <span>
                <FaIcons.FaYoutube />
              </span>
              Visit My Channel
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;

import React from "react";
import "./skills.css";
import { data } from "./skillsData";

const Skill = (item, index) => {
  return (
    <div className="box" key={index}>
      <div className="s-icons">
        <img src={item.icon} alt={item.alt} />
      </div>
      <h3>{item.title}</h3>
      <p>{item.text}</p>
      <a
        href="https://ar.wikipedia.org/wiki/%D9%85%D9%87%D8%AF%D9%8A_%D8%A7%D9%84%D9%82%D9%84%D8%A7%D9%81"
        className="read"
        target="_blank"
        rel="noreferrer"
      >
        Read More
      </a>
    </div>
  );
};

const Skills = () => {
  return (
    <>
      <section className="skills" id="skills">
        <div className="main-text">
          <p>What are My Skills and Achievements</p>
          <h2>Extracurricular</h2>
        </div>
        <div className="skills-content">{data.map(Skill)}</div>
      </section>
    </>
  );
};

export default Skills;

export const Stockpiles = [
  {
    id: 1,
    num: 1,
    text: "Are aggregate stockpiles separated by walls or clear space with signed labeling?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 2,
    num: 2,
    text: "Are the stockpiles free of significant contamination and segregation? (Plant drain-out aggregate is acceptable if the aggregate size is similar and uncoated).",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 3,
    num: 3,
    text: "Is the aggregate in good condition with no signs of degradation on the stockpile due to vehicle/plant movements?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 4,
    num: 4,
    text: "Is the truck tipping area at the stockpile level? (Non-level surfaces can cause trucks to tip over.)",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
];

export const ColdFeed = [
  {
    id: 5,
    num: 5,
    text: "Has each aggregate size its own designated and labeled bin/hopper?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 6,
    num: 6,
    text: "Number of cold bins are appropriate for the project?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 7,
    num: 7,
    text: "Are bins/hoppers in good condition with no holes, with bin wall divider plates between each?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 8,
    num: 8,
    text: "Is aggregare overflaw between hoppers being prevented by divider plates?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 9,
    num: 9,
    text: "If meshes (grizzlies) are fitted over the bins/hoppers, are they in reasonable condition and fit for purpose?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 10,
    num: 10,
    text: "Are the feeder gates, feeder belts and vibrators in good condition?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 11,
    num: 11,
    text: "Are there no-flow paddles/sensors on each feeder and are they working?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 12,
    num: 12,
    text: "Date of the last feeder calibration.",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 13,
    num: 13,
    text: "Is the aggregate collecting conveyor in good condition, with no holes or splits, or evidence of tracking (side movement) and the belt scrapers are in good order?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
];

export const Dryer = [
  {
    id: 14,
    num: 14,
    text: "There is no significant aggregate spillage under the dryer conveyor, the dryer drum or around the base of the hot elevator?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 15,
    num: 15,
    text: "Are the dryer lifters  and flights are in good condition without undue bending or deformation and none missing (when dryer is cold and stopper)?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 16,
    num: 16,
    text: "Date of last pyrometer, thermocouple or probe calibration.",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 17,
    num: 17,
    text: "Has the drum exit chute temperature equipment been checked recently with a referenced laboratory probe?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
];

export const DustCollector = [
  {
    id: 18,
    num: 18,
    text: "Does the pre-skimmer or knockout box return the collected fine sand to the plant hot elevator? (if fitted)",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 19,
    num: 19,
    text: "Is the bad house extracted dust: a: returned to a silo for re-use? b: directly fed back into the asphalt plant? c: waste",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 20,
    num: 20,
    text: "Does the laboratory monitor the gradation of the coarse preskimmer or knockout box fines and bag house filler?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 21,
    num: 21,
    text: "Date of last certified plant stack emission test.",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 22,
    num: 22,
    text: "Is there a dust emission monitoring system on the plant?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 23,
    num: 23,
    text: "Is the plant stack emission visually clean with no obvious dust or smoke?",
    ref: "Section 6 - Part 7",
    appendix: "",
    cName: "",
  },
  {
    id: 24,
    num: 24,
    text: "The collected dust from the drying process normally passes through a coarse collector which will pass the coarse particles, substantially greater than 0.075mm, back to the hot elevator or to a silo for the controlled return to the asphalt mix. Is coarse filler returned to the hot elevator or stored in a silo for reuse?",
    ref: "Section 7. Part 8.5.3",
    appendix: "",
    cName: "",
  },
  {
    id: 25,
    num: 25,
    text: "The plant is equipped with a filler silo for recovered filler and, if needed, an additional dry storage silo for imported mineral filler.",
    ref: "Section 7. Part 8.5.3",
    appendix: "",
    cName: "",
  },
  {
    id: 26,
    num: 26,
    text: "Filler lines shall not leak.",
    ref: "Section 7. Part 8.5.2",
    appendix: "",
    cName: "",
  },
];

export const AggregateScreening = [
  {
    id: 27,
    num: 27,
    text: "There is no spillage around the screen deck area.",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 28,
    num: 28,
    text: "The last screen inspection indicates no defects.",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 29,
    num: 29,
    text: "Have screens aperture sizes been verified and documented? Do screen sizes match the ones displayed on the operator PC?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 30,
    num: 30,
    text: "Date of screen aperture sizes verification: is it valid?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 31,
    num: 31,
    text: "Date of last screen deck inspection: is it valid?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 32,
    num: 32,
    text: "There are no signs of damage or wear, especially adjacent to screen bearings or shafts? (cold plant inspection).",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 33,
    num: 33,
    text: "IS there no significant dust emission from the screen housing lids, covers or doors?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 34,
    num: 34,
    text: "Are there no holes, splits or tears in the screen meshes and is there any apparent and significant pedding or blockages?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 35,
    num: 35,
    text: "Are there no loose screen meshes that need tightening or support bars that are loose and need welding? (cold plant inspection)",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 36,
    num: 36,
    text: "Is there no side seal seepage which could contaminate screened aggregate or wear other parts? (cold plant inspection)",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 37,
    num: 37,
    text: "Does hot bin screened aggregate contain no more than 10% oversize dor undersize material? Are the mix tolerances valid? (Where oversize or undersize exceeds 10% but screening is consistent and the JMF is consistently met, this will not be considered an unacceptable observation).",
    ref: "Section 6 - Part 7",
    appendix: "7.8.6.1",
    cName: "",
  },
];

export const HotAggregateBins = [
  {
    id: 38,
    num: 38,
    text: "Is there no indication of hot bin oversize or undersize in hot bin drain-outs?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 39,
    num: 39,
    text: "Bin 1 Aggregate size 0/5 mm",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 40,
    num: 40,
    text: "Bin 1 Aggregate size 5/12 mm",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 41,
    num: 41,
    text: "Bin 1 Aggregate size 12/22 mm",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 42,
    num: 42,
    text: "Bin 1 Aggregate size 22/40 mm",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 43,
    num: 43,
    text: "Bin 5 Aggregate size",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 44,
    num: 44,
    text: "Bin 6 Aggregate size",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 45,
    num: 45,
    text: "Are there individual hot stone bin overflow chutes/pipes free of blockage or damage?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
];

export const AggregateScreening2 = [
  {
    id: 46,
    num: 46,
    text: "Are there no holes or damaged seams or welds in the hot bin walls, especially in the corners and in the lower half of the bins",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 47,
    num: 47,
    text: "Are hot stone bin doors not sticking or seize? Are the doors free of holes and leakage?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 48,
    num: 48,
    text: "Does the plant have at least three (3) storage bins for hot aggregateof sufficient size, to supply the pugmill when its operating at full capacity?",
    ref: "Section 6 - Part 7",
    appendix: "7.8.7.1",
    cName: "",
  },
  {
    id: 49,
    num: 49,
    text: "Are hot bins equipped with continuous level indicators?",
    ref: "Section 6 - Part 7",
    appendix: "7.8.7.1",
    cName: "",
  },
];

export const ThermometricEquipment = [
  {
    id: 50,
    num: 50,
    text: "Are calibrated thermometric instruments placed in the hot fines bin?",
    ref: "Section 6 - Part 7",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 51,
    num: 51,
    text: "Is the drum exit chute temperature pyrometer, thermocouple or probe, clean, working and in calibration?",
    ref: "Section 6",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 52,
    num: 52,
    text: "Are temperature control devices installed to control the mix temperature?",
    ref: "Section 6",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 53,
    num: 53,
    text: "Is the pub mill or the temporary storage bin equipped with infrared thermometers or equivalent?",
    ref: "Section 6",
    appendix: "Appendix C",
    cName: "",
  },
];

export const WeighingAndMeasuringApparatus = [
  {
    id: 54,
    num: 54,
    text: "Is aggregate weighing sequence from coarse size to fine size?",
    ref: "Section 6",
    appendix: "Appendix C",
    cName: "",
  },
  {
    id: 55,
    num: 55,
    text: "Are the calibration of the aggregate, filler and bitumen weighing apparatus, current and in date?",
    ref: "Section 6",
    appendix: "Appendix C",
    cName: "",
  },
];

import React from "react";
import "./form1.css";
import Header from "../../components/header/Header";
import { form1Data } from "./form1Data";
import Sig from "../../components/sigPad/Sig";

const tableContent = (item, index) => {
  return (
    <tr key={index}>
      <td className={item.cName}>
        <input className="input-no" value="لا" type="checkbox"></input>
      </td>
      <td className={item.cName}>
        <input className="input-yes" value="نعم" type="checkbox"></input>
      </td>
      <td className={item.cName}>{item.text}</td>
      <td>{item.num}</td>
    </tr>
  );
};

const Form1 = () => {
  return (
    <>
      <Header />
      <div className="container">
        <h3 className="sub-title">نموذج متابعة تقرير تحليل الفجوات</h3>
        <div className="form-t">
          <table>
            <tr>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل اسم المصنع"
                />
                :اسم المصنع
              </td>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل اليوم"
                />
                :اليوم
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل مصدر الصلبوخ"
                />
                :مصدر الصلبوخ
              </td>
              <td>
                <input
                  type="date"
                  className="table-1"
                  placeholder="...ادخل التاريخ"
                />
                :التاريخ
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل نوعية الخلطة"
                />
                :نوعية الخلطة
              </td>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل اسم المنطقة"
                />
                :المنطقة
              </td>
            </tr>
          </table>
        </div>
        <table className="table-text">
          <tr>
            <th>لا</th>
            <th>نعم</th>
            <th>: تشوينات المواد</th>
            <th>الرقم</th>
          </tr>
          {form1Data.map(tableContent)}
        </table>

        {/* NOTES */}
      </div>
      <div className="note-container">
        <textarea
          type="text"
          className="note-input"
          maxLength="300"
          placeholder="... اكتب ملاحظاتك هنا"
        />
        <h5 className="note-title">:الملاحظات</h5>
      </div>

      <div className="container">
        <div className="form-t">
          <table>
            <tr>
              <th>ممثل المصنع</th>
              <th>ممثل إدارة مختبرات الطرق</th>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل الاسم"
                />
                :الاسم
              </td>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل الاسم"
                />
                :الاسم
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="date"
                  className="table-1"
                  placeholder="...ادخل التاريخ"
                />
                :التاريخ
              </td>
              <td>
                <input
                  type="date"
                  className="table-1"
                  placeholder="...ادخل التاريخ"
                />
                :التاريخ
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل التوقيع"
                />
                :التوقيع
              </td>
              <td>
                <input
                  type="text"
                  className="table-1"
                  placeholder="...ادخل التوقيع"
                />
                :التوقيع
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="signature">
        <Sig />
      </div>
    </>
  );
};

export default Form1;

import React, { useRef, useState } from "react";
import "./contact.css";
import emailjs from "emailjs-com";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";

const Contact = () => {
  const formRef = useRef();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_7ue15na", // Service ID service_7ue15na
        "template_saq3qcd", // Template ID template_saq3qcd
        formRef.current,
        "X60ZU_FD3tSyYORAv" // Public Key X60ZU_FD3tSyYORAv
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitted(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleReset = () => {
    setIsSubmitted(false);
  };

  return (
    <>
      <section className="contact" id="contact">
        <div className="contact-text">
          <h2>
            Contact <span>Me!</span>
          </h2>
          <h4>Let's stay in touch...</h4>
          <p>Do not hesitate to contact me if you have any questions.</p>
          <div className="list">
            <li>
              <a href="mailto:10alqallaf@gmail.com">10alqallaf@gmail.com</a>
            </li>
          </div>
          <div className="contact-icons">
            <a href="https://www.facebook.com/10ALQALLAF/">
              <FaIcons.FaFacebookF />
            </a>
            <a href="https://twitter.com/10alqallaf">
              <FaIcons.FaTwitter />
            </a>
            <a href="https://instagram.com/10alqallaf">
              <FaIcons.FaInstagram />
            </a>
            <a href="https://www.youtube.com/@mahdialqallaf5113">
              <FaIcons.FaYoutube />
            </a>
          </div>
        </div>

        <div className="contact-form">
          {!isSubmitted ? (
            <form ref={formRef} onSubmit={handleSubmit}>
              <input type="text" placeholder="Name" name="user_name" required />
              <input
                type="email"
                placeholder="Email"
                name="user_email"
                required
              />
              <input
                type=""
                name="user_mobile"
                placeholder="Your Mobile Number"
                required
              />
              <textarea
                name="message"
                id=""
                cols="35"
                rows="10"
                placeholder="Write your message here ..."
                required
              ></textarea>
              <input
                type="submit"
                value="Send Message"
                className="submit"
                required
              />
            </form>
          ) : (
            <div className="reset-container contact-text">
              <div className="thnx">
                <h2>
                  THANK <span>YOU</span>!
                </h2>
                <BsIcons.BsCheckCircle className="check" />
                <input
                  onClick={handleReset}
                  value="Submit another message"
                  className="reset-btn"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Contact;

import React from "react";
import "./sig.css";
import SignatureCanvas from "react-signature-canvas";

const Sig = () => {
  return (
    <>
      <div className="signature-container">
        <SignatureCanvas
          penColor="black"
          canvasProps={{ width: 1000, height: 300, className: "sigCanvas" }}
        />
      </div>
    </>
  );
};

export default Sig;

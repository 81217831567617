import Worker from "../../images/icons/family.png";
import Athlete from "../../images/icons/athlete.png";
import OpenBook from "../../images/icons/open-book.png";

export const data = [
  {
    icon: Worker,
    alt: "Personal Life",
    title: "Personal Life",
    text: "After completing his high school studies, Mahdi got married in 2004 and has been blessed with five children: Hassen, Ahmed, Jasem, Hour, and Hawraa.",
  },
  {
    icon: OpenBook,
    alt: "University",
    title: "University",
    text: "In the summer of 2007-2008, Mahdi applied to Gulf University in Bahrain to pursue a Bachelor's degree in Civil Engineering after obtaining his diploma from the College of Technological Studies in Kuwait. He successfully completed his studies and graduated with a Bachelor's degree in Civil Engineering in 2011.",
  },
  {
    icon: Athlete,
    alt: "Hobbies & Interests",
    title: "Hobbies & Interests",
    text: "Mahdi has a wide range of interests and hobbies that keep him busy outside of work. He has a passion for cars, loves playing Padel tennis and billiards, and enjoys a good cup of coffee. Mahdi is a social person who likes to spend time in coffee shops and socialize with friends and members of the community. Additionally, he has a love for travel and enjoys exploring new destinations whenever possible. Finally, Mahdi is also dedicated to maintaining his fitness and has a keen interest in fashion, with a sharp eye for style.",
  },
];

import "./App.css";
import { Routes, Route } from "react-router-dom";
import Form1 from "./forms/pages/form1/Form1";
import Form2 from "./forms/pages/form2/Form2";
// import Buttons from "./forms/components/buttons/Buttons";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Skills from "./components/Skills/Skills";
// import Portfolio from "./components/Portfolio/Portfolio";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Menu from "./components/Menu/Menu";
import Tricks from "./components/Tricks/Tricks";

function App() {
  return (
    <>
      <Menu />
      <Home />
      <About />
      <Skills />
      <Contact />
      <Footer />
      <Tricks />
      <Routes>
        <Route exact path="/form1" element={<Form1 />} />
        <Route exact path="/form2" element={<Form2 />} />
      </Routes>
      {/* <Buttons /> */}
    </>
  );
}

export default App;

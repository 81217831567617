import React from "react";

const Tricks = () => {
  const scrollTopButton = document.querySelector("#scroll-top-button");

  const onScroll = (event) => {
    const scrollPosition = event.target.scrollingElement.scrollTop;
    scrollTopButton.classList.toggle("visible", scrollPosition > 0);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  document.addEventListener("scroll", onScroll);

  return (
    <>
      <button
        id="scroll-top-button"
        onClick={scrollToTop}
        className="scroll-top-button"
        type="button"
      >
        <span className="material-symbols-outlined">arrow_upward</span>
      </button>
    </>
  );
};

export default Tricks;

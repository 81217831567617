export const form1Data = [
  {
    num: 1,
    text: "هل يوجد علامات ارشادية عاكسة على تشوينات المواد؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 2,
    text: "هل تشوينات المواد مفصولة كلياً بشكل صحيح بجدار أو حاجز جيد؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 3,
    text: "هل التشوينات نظيفة وخالية من النفايات والتلوث؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 4,
    text: "هل الإنارة جيدة على التشوينات والأقماع الباردة؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": الأقماع الباردة",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 5,
    text: "هل كل حجم ركام يمتلك قمع خاص مع علامة إرشادية؟ وكم عددهم؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 6,
    text: "هل الأقماع الباردة في حالة جيدة مع عدم وجود ثقوب، ووجود حواجز مناسبة بين كل منها؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 7,
    text: "هل المناخل على الأقماع الباردة في حالة جيدة؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 8,
    text: "هل بوابات التغذية وأحزمة التغذية والهزاز في حالة جيدة؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 9,
    text: "هل توجد أجهزة استشعار التدفق في كل وحدة تغذية وهل تعمل بشكل جيد؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 10,
    text: "هل السير الناقل للمواد الباردة في حالة جيدة مع عدم وجود ثقوب أو انشقاق أو ميلان في الحزام؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": جامع الأتربة",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 11,
    text: "هل اتبعاثات المصنع نظيفة بصرياً بدون غبار أو دخان واضح؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": المجفف (المحمص)",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 12,
    text: "هل يوجد انسكاب ركام كبير تحت ناقل المجفف أو اسطوانات المجفف أو حول قاعدة المصعد الساخن؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 13,
    text: "هل رافعات المجفف في حالة جيدة بدون انحناء أو أي شي مفقود؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": خزان البيتومين",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 14,
    text: "هل تم تركيب مقياس حرار ة على جميع خزانات البيتومين ووجود علامات عاكسة؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 15,
    text: "هل سخان الخزانات يعمل بشكل صحيح؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 16,
    text: "هل جميع الخزانات والأنابيب والمضخات وعدادات التدفق معزولة بشكل صحيح والأجزاء المحيطة نظيفة وفي حالة جيدة مع عدم وجود تسريبات؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 17,
    text: "هل تحتوي خزانات البيتومين على نقطة لأخذ العينات؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 18,
    text: "هل يوجد سجلات متابعة لدرجات حرارة خزانات البيتومين ويسجلها يومياً؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": مناخل المواد (الغرابيل)",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 19,
    text: "هل يوجد تسريب حول منطقة الغرابيل؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 20,
    text: "هل يفحص طاقم المصنع الغرابيل بشكل مستمر ويسجلها؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": الأقماع الساخنة",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 21,
    text: "هل توجد عيوب لم يتم اصلاحها في جدران الحاويات الساخنة أو في تصريق الصلبوخ الفائض؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": موازين المواد والخلاطة",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: 22,
    text: "هل يوجد تسريب في ميزان البيتومين أو ميزان المواد أو ميزان الفلر (الدست)؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 23,
    text: "هل يوجد تسريب في خلاطة الإسفلت؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 24,
    text: "هل الكفوف نظيفة تعمل بشكل جيد في الخلاطة ولا يوجد بها تآكل؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 25,
    text: "هل بوابة الخلاطة والحزان تعمل بشكل جيد؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: 26,
    text: "هل الخلاطة أو الخزانات مزودة بمقياس حرارة رقمي أو أشعة تحت حمراء؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": غرفة التحكم",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: "27",
    text: "هل بيانات الخلطة التصميمية واضحة في نظام تشغيل المصنع؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "28",
    text: "هل يوجد أرشيف لجميع عمليات التصنيع في المصنع؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "",
    text: ": مختبر المصنع",
    yes: "",
    no: "",
    cName: "sub-t",
  },
  {
    num: "29",
    text: "هل يوجد كشف بأسماء العاملين مع المسمى الوظيفي والمؤهلات والدورات المعتمدة في مكان واضح في المختبر؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "30",
    text: "هل يوجد كشف بأسماء الأجهزة مع الرقم التسلسلي مع تاريخ معايرة كل جهاز في مكان واضح في المختبر؟",
    yes: "",
    no: "",
    cName: "",
  },
  {
    num: "31",
    text: " ؟QCS 2014 هل يوجد لدى المختبر سجل تقارير اختبارات ضغط الجودة حسب مواصفات",
    yes: "",
    no: "",
    cName: "",
  },
];

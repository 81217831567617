import React from "react";
import "./about.css";
import Mahdi from "../../images/pics/4a.png";

const About = () => {
  return (
    <>
      <section className="about" id="about">
        <div className="about-img">
          <img src={Mahdi} alt="Mahdi" />
        </div>
        <div className="about-text">
          <h2>
            About <span>Me</span>
          </h2>
          <h4>Handball Player</h4>
          <p>
            Born in Kuwait City, Kuwait in 1984, Mahdi's passion for sports
            started at a young age, particularly in football. During his middle
            school years, he held onto his dream of becoming a professional
            footballer, and thought that it might come true when
            <em> (Qadsia SC | Qadsiya club) </em> approached him. However, to
            his surprise, they offered him a position in their handball team
            instead of the football team.
          </p>
          <p>
            Mahdi's experience with the Qadsiya football club led him to
            discover a newfound love for handball, but he never lost his passion
            for football. During the summer, he started training as a goalkeeper
            with Al Arabi football club, hoping to realize his dream of becoming
            a professional footballer. However, his hopes were dashed when the
            club rejected him a few weeks later, leaving him feeling
            disappointed.
          </p>
          <p>
            Mahdi's rejection from
            <em> (Al-Arabi SC "Kuwait" | Al Arabi football club)</em> was a
            major disappointment, but it only fueled his competitive spirit. He
            turned his attention to handball with
            <em> (Qadsia SC | Qadsiya club)</em>, which happened to be a fierce
            rival of <em> (Al-Arabi SC "Kuwait" | Al Arabi)</em>. Mahdi saw this
            as an opportunity to prove himself and make a statement to his
            former club.
          </p>
          <a
            href="https://ar.wikipedia.org/wiki/%D9%85%D9%87%D8%AF%D9%8A_%D8%A7%D9%84%D9%82%D9%84%D8%A7%D9%81"
            className="btn"
            target="_blank"
            rel="noreferrer"
          >
            More About
          </a>
        </div>
      </section>
    </>
  );
};

export default About;

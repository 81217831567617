import React from "react";
import "./footer.css";
import ScrollToTop from "./ScrollToTop.jsx";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <div className="last-text">
          <p>Copyright © 2023 by Mahdi Al Qallaf All Rights Reserved.</p>
        </div>
        <ScrollToTop />
      </div>
    </>
  );
};

export default Footer;

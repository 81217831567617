import React from "react";
import "./header2.css";
import NewKw from "../../../images/new-kw.png";
import KwLogo from "../../../images/kw-logo.png";

const Header = () => {
  return (
    <>
      <div className="h-container">
        <img src={NewKw} alt="" className="col logo-1" />
        <div className="col title2">
          <h2>
            Ministry of Public Works
            <br />
            Government Center for Testing & Quality Research
            <br />
            Roads Laboratories Department
            <br />
            <span className="sub-main-title2">
              GAP ANALYSIS WORKSHEET REPORT
            </span>
          </h2>
        </div>
        <img src={KwLogo} alt="" className="col logo-2" />
      </div>
    </>
  );
};

export default Header;
